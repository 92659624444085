import type { ReactNode } from "react";
import React from "react";
import { PageHome } from "@aspen/pages";

class Home extends React.Component {
  render(): ReactNode {
    return <PageHome {...this.props} />;
  }
}

export default Home;
